<template>
  <section class="socooc-partnership">
    <router-link :to="{ name: 'home' }" class="hero-link">
      <img
        src="~@/assets/img/pages/landings/socooc-partnership-hero.png"
        alt="Du 1er au 30 avril nous vous offrons votre cuisine. Ensemble, construisons vos instants de bonheur. En partenariat avec Socooc. Configurez votre projet de maison !"
      />
    </router-link>

    <div class="description-container">
      <p>
        Du 1er au 30 avril bénéficiez d’une prise en charge de 3 500 € par l’agence Maisons Alysia participant à l’opération sur l’aménagement de votre cuisine (participation sous la forme d’un coupon d’achat valable pour tout achat de mobilier de cuisine hors électroménager dans le magasin SoCoo’C partenaire désigné).
      </p>
    </div>

    <div class="agencies-container">
      <h3>Liste des agences Maisons Alysia participantes :</h3>
      <ul>
        <li v-for="agency in agencies" :key="agency.label">
          <router-link :to="{ name: agency.routeName, params: { slugName: agency.slugName }}">{{ agency.label }}</router-link>
        </li>
      </ul>
    </div>

    <div class="conditions-container">
      <card-type-img title="Modalités de l'opération" >
        <template v-slot:content>
          <p>Remise d’un bon d’achat de 3 500 € pour toute signature d’un contrat de construction de maison individuelle avec le constructeur Maisons Alysia participant à l’opération (voir liste ci-dessus) du 1er au 30 avril 2023.</p>
          <ul>
            <li>La prise en charge de ce bon est conditionnée à l’exécution du CCMI et sera effective à la livraison de la cuisine.</li>
            <li>Bon déductible de la valeur meubles de votre cuisine, il ne pourra faire l’objet ni d’un remboursement ni de rendu de monnaie.</li>
            <li>Offre entendue hors équipements électroménagers et sanitaires, hors prestations livraison et pose.</li>
            <li>Les prix cités ici s’entendent en euros TTC.</li>
            <li>Offre non cumulable avec d’autres offres en cours ou à venir proposées par SoCoo’c ou l’agence Maisons Alysia.</li>
            <li>Cette offre est exclusivement proposée aux clients du constructeur Maisons Alysia.</li>
          </ul>
        </template>
        <template v-slot:img>
          <img src="~@/assets/img/pages/landings/socooc-conditions.png" alt="" />
        </template>
      </card-type-img>
    </div>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  name: 'socooc-partnership',
  components: { CardTypeImg },
  data() {
    return {
      agencies: [
        { label: 'Angers – Verrières en Anjou (49480)', routeName: 'maine-et-loire-page-agence', slugName: 'agence-49-nord' },
        { label: 'Challans (85300)', routeName: 'vendee-page-agence', slugName: 'agence-85-nord' },
        { label: 'Chartres (28000)', routeName: 'eure-et-loir-page-agence', slugName: 'agence-28' },
        { label: 'Dinan - Taden (22100)', routeName: 'cotes-darmor-page-agence', slugName: 'agence-35-22' },
        { label: 'La Roche sur Yon (85000)', routeName: 'vendee-page-agence', slugName: 'agence-85-sud' },
        { label: 'Le Havre - Gonfreville L\'Orcher (76700)', routeName: 'seine-maritime-page-agence', slugName: 'agence-76-ouest' },
        { label: 'Le Mans - Saint Saturnin (72650)', routeName: 'sarthe-page-agence', slugName: 'agence-72' },
        { label: 'Nantes Nord - La Chapelle sur Erdre (44240)', routeName: 'loire-atlantique-page-agence', slugName: 'agence-44-nord' },
        { label: 'Niort - Bessines (79000)', routeName: 'deux-sevres-page-agence', slugName: 'agence-85-79' },
        { label: 'Poitiers - Migné-Auxances (86440)', routeName: 'vienne-page-agence', slugName: 'agence-86' },
        { label: 'Rennes Nord - Saint Grégoire (35760)', routeName: 'ille-et-vilaine-page-agence', slugName: 'agence-35-nord' },
        { label: 'Rouen - Le Petit Quevilly (76140)', routeName: 'seine-maritime-page-agence', slugName: 'agence-76-sud' },
        { label: 'Saint André de Cubzac (33240)', routeName: 'gironde-page-agence', slugName: 'agence-33-nord' },
        { label: 'Tours - Saint Avertin (37550)', routeName: 'indre-et-loire-page-agence', slugName: 'agence-37-sud' },
        { label: ' Vannes - Séné (56860)', routeName: 'morbihan-page-agence', slugName: 'agence-56-sud' },
      ],
    };
  },
};
</script>

<style lang="sass">
.socooc-partnership
  padding: 0 0 4rem
  background: $medium-bg
  .hero-link img
    width: 100%
    @media (max-width: 768px)
      content: url('~@/assets/img/pages/landings/socooc-partnership-hero-mobile.png')
  .description-container
    max-width: 1000px
    margin: auto
    padding: 4rem 2rem
    p
      @include heading-md
      font-size: 29px
      line-height: 32px
      text-align: center
      color: $black
  .agencies-container
    background-color: $primary
    padding: 3rem 2rem
    ul, h3
      max-width: 1000px
      margin: auto
    h3, a
      color: white
    h3
      @include heading-md
      font-size: 29px
      text-align: center
      margin-bottom: 3rem
    ul
      list-style: none
      padding: 0
      column-count: 3
      column-fill: balance-all
      li:not(:first-child)
        margin-top: 2rem
  .conditions-container
    padding: 3rem
    .card-type-img
      max-width: 1200px
      margin: auto

  @media (max-width: 768px)
    .hero-link img
      content: url('~@/assets/img/pages/landings/socooc-partnership-hero-mobile.png')
    .description-container
      p
        font-size: 20px
        line-height: 28px
    .agencies-container
      h3
        @include heading-md-mobile
        text-align: left
      ul
        column-count: 1

</style>
